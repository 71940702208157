import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { StyledAutoPlaySwipeableViews, StyledBox } from './style';

function SwipeableTextMobileStepper ({ cardData }: { cardData: any }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = cardData?.length;
  const [slideNo, setSlideNo] = React.useState(maxSteps);

  const handleNext = () => {
    setSlideNo((prevSlideNo: number) => prevSlideNo - 1);

    if (slideNo <= 3) {
      setActiveStep(0);
      setSlideNo(maxSteps);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setSlideNo(slideNo + 1);
    setSlideNo((prevSlideNo: number) => prevSlideNo - 1);

    if (slideNo <= 3) {
      setActiveStep(0);
      setSlideNo(maxSteps);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step: number) => {
    setSlideNo((prevSlideNo: number) => prevSlideNo - 1);

    if (slideNo <= 3) {
      setActiveStep(0);
      setSlideNo(maxSteps);
    } else {
      setActiveStep(step);
    }
  };

  return (
    <StyledBox>
      <StyledAutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {cardData?.map((item: any, index: number) => (
          <Link
            href={`/${item.category?.slug}-${item.mastercategory?.slug}/${item?.slug}`}
            key={item.id}
            style={{ width: '100%', padding: '1% 2%' }}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Image
                unoptimized
                src={`${process.env.CONTENT_BASE_URL}/${item.image_url}`}
                alt={item.name}
                width={0}
                height={0}
                layout="responsive"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : null}
          </Link>
        ))}
      </StyledAutoPlaySwipeableViews>

      <Box>
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 3}
          sx={{
            position: 'absolute',
            top: '60%',
            right: '5%',
            borderRadius: '50px',
          }}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
        <Button
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{
            position: 'absolute',
            top: '60%',
            left: '2%',
            borderRadius: '50px',
          }}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </Button>
      </Box>
    </StyledBox>
  );
}

export default SwipeableTextMobileStepper;
