import { Stack, Typography } from '@mui/material';

import { FlexRowCenter } from 'libs/components/flex-box';
import InfiniteHorizontalScrollBar from 'libs/components/InfiniteScroll';


function TrustedPartners () {
  return (
    <Stack justifyContent={'center'} width={'100%'} my={'2%'} spacing={5}>
      <Typography
        fontSize={{ lg: '2.25rem', sm: '1.9rem', xs: '1.8rem' }}
        fontWeight={600}
        textTransform={'capitalize'}
        width={'100%'}
        textAlign={'center'}
      >
					Trusted & Featured by the top brands
      </Typography>

      <FlexRowCenter padding={'0 0.3rem'}>
        <InfiniteHorizontalScrollBar
          images={[
            {
              src: `${process.env.CONTENT_BASE_URL}/createstudio.webp`,
              // src: '${process.env.CONTENT_BASE_URL}/createstudio.webp',
              alt: 'createstudio',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/cva.webp`,
              alt: 'cva',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/reliance.webp`,
              alt: 'reliance',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/baru.webp`,
              alt: 'baru',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/invideo.webp`,
              alt: 'invideo',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/mainframe.webp`,
              alt: 'mainframe',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/wondershare.webp`,
              alt: 'wondershare',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/benoits.webp`,
              alt: 'benoits',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/shahyadri.webp`,
              alt: 'shahyadri',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/yourstory.webp`,
              alt: 'yourstory',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/bbc.webp`,
              alt: 'bbc',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/times-of-india.webp`,
              alt: 'times-of-india',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/abp-news.webp`,
              alt: 'abp-news',
            },
            {
              src: `${process.env.CONTENT_BASE_URL}/zee-news.webp`,
              alt: 'zee-news',
            },

          ]}
        />
      </FlexRowCenter>
    </Stack>
  );
}

export default TrustedPartners;
