import Image from 'next/image';

import { styled } from '@mui/material';
export const DesktopImage = styled(Image)(({theme}) => ({
  width: '100%',
  height: '100%',
  marginTop: '2.5%',
  background: 'transparent',
  [theme.breakpoints.down('sm')]:{
    display:'none'
  }

}));
export const MobileImage = styled(Image)(({theme}) => ({
  width: '100%',
  height: '100%',
  marginTop: '2.5%',
  background: 'transparent',
  display:'none',
  [theme.breakpoints.down('sm')]:{
    display:'block'
  }

}));
