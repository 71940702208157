import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, styled } from '@mui/material';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: 400,
  },
  [theme.breakpoints.down('lg')]: {
    width: 'calc(35% - 20px)',
    height: 'calc(35% - 10px)'
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledAutoPlaySwipeableViews = styled(AutoPlaySwipeableViews)(({ theme }) => ({
  overflow: 'unset !important',
  [theme.breakpoints.down('sm')]: {
    overflow: 'hidden',
  },
}));

