import { Grid, styled, Typography } from '@mui/material';

import { themeColors } from 'theme/theme-colors';


export const StyledTypography = styled(Typography)(({theme})=>({
  color: themeColors.primary.main,
  marginLeft: 'auto',
  display: 'inline-block',
  verticalAlign: 'middle',
  cursor: 'pointer',
  [theme.breakpoints.down(620)]:{
    display: 'none'
  }
}));


export const StyledGrid = styled(Grid)(({theme})=>({
  [theme.breakpoints.down(620)]:{
    display: 'flex'
  }
}));
